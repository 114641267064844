import React, { useCallback, useEffect, useState } from "react";
import { Button, InlineStack, Text } from "@shopify/polaris";
import { getValueFromNestedObject } from "@/Utils/Index";

export default function LoctionPostion(props) {
  const {
    form: { values, setFieldValue },
    field: { name, label },
  } = props;
  const value = getValueFromNestedObject(values, name);
  const [inputValue, setInputValue] = useState(value);

  const onFormChange = useCallback(
    (e) => {
      setInputValue(e);
    },
    [inputValue]
  );
  useEffect(() => {
    setFieldValue(name, inputValue);
  }, [inputValue]);

  const ButtonWithSVG = ({ value, path, iconPosition }) => (
    <Button variant="plain" onClick={(e) => onFormChange(iconPosition)}>
      <svg xmlns="http://www.w3.org/2000/svg" width="95" height="95" viewBox="0 0 95 95" fill="none">
        <rect
          x="0.5"
          y="0.5"
          width="77"
          height="77"
          fill={value === iconPosition ? "#E9F2FF" : "white"}
          stroke="#D2D5D8"
        />
        <path
          d="M16.1875 26.4375H60.8125V62.5625H16.1875V26.4375ZM17.8005 28.0126V60.9874H59.1995V28.0126H17.8005Z"
          fill={value === iconPosition ? "" : "#5C5F62"}
        />
        <path d={path} fill={value === iconPosition ? "" : "#5C5F62"} />
      </svg>
    </Button>
  );

  const icons = [
    {
      iconPosition: "topLeft",
      path: "M19.375 29.625H30V34.9375H19.375V29.625Z",
    },
    {
      iconPosition: "topMiddle",
      path: "M33.8125 29.625H43.4375V34.9375H33.8125V29.625Z",
    },
    {
      iconPosition: "topRight",
      path: "M47 29.625H57.625V34.9375H47V29.625Z",
    },
    {
      iconPosition: "bottomLeft",
      path: "M19.375 54.0625H30V59.375H19.375V54.0625Z",
    },
    {
      iconPosition: "bottomMiddle",
      path: "M33.8125 54.0625H43.4375V59.375H33.8125V54.0625Z",
    },
    {
      iconPosition: "bottomRight",
      path: "M47 54.0625H57.625V59.375H47V54.0625Z",
    },
  ];

  return (
    <InlineStack gap="300" align="center">
      {icons.map((iconData, index) => (
        <ButtonWithSVG key={index} value={value} path={iconData?.path} iconPosition={iconData?.iconPosition} />
      ))}
    </InlineStack>
  );
}
