import React, { useCallback, useContext, useEffect, useState } from "react";
import { Banner, Button, InlineStack, Modal, Text } from "@shopify/polaris";
import { t } from "i18next";
import { useAuthenticatedFetch } from "@/Api/Axios";
import DissmissableBanner from "@/Components/Common/DissmissableBanner";
import { navigate } from "@/Components/Common/NavigationMenu";
import { ProfileContext } from "../../Context/ProfileContext";
import { checkMoneyFormat } from "../../Utils/AppUtils";

export default function IntergationBanner() {
  const { profileData } = useContext(ProfileContext);
  const setNavigate = navigate();
  const [integrated, setIntegrated] = useState(true);
  const fetch = useAuthenticatedFetch();

  const fetchData = useCallback(async () => {
    const integrationRes = await fetch.get("checkIntegration");
    const res = checkMoneyFormat(profileData, integrationRes);
    setIntegrated(res?.integrated);
  }, [profileData]);

  useEffect(() => {
    if (profileData) {
      fetchData();
    }
  }, [profileData]);
  const GoToSetting = () => {
    setNavigate("/settings?tabIndex=1");
  };
  return (
    <div>
      {!integrated && (
        <DissmissableBanner
          bannerName="IntegrationBanner"
          title="Integration Required"
          bannerText={
            <InlineStack BlockAlign="baseline" gap={200}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Text>{t("cart.integrationBannerMassage")}</Text>
              </div>
              <Button onClick={GoToSetting}>{t("Go to Settings")}</Button>
            </InlineStack>
          }
          tone="info"
        />
      )}
    </div>
  );
}
