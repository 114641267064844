import { Outlet } from "react-router-dom";
import IntergationBanner from "./Pages/Settings/IntergationBanner";

export const AppRoute = ({ type }) => {
  if (type === "private") {
    return (
      <>
        <div style={{ margin: "0px 24px" }}>
          <IntergationBanner />
        </div>
        <Outlet />
      </>
    );
  }
};
