import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Badge, BlockStack, Box, Button, Card, Divider, Icon, InlineStack, Text, Tooltip } from "@shopify/polaris";
import { CircleTickMajor, ClockMajor, DiamondAlertMajor, DiscountsMinor } from "@shopify/polaris-icons";
import { promocodeFormFields } from "@/Assets/Mocks/CommonPricing.mock";
import CommonForm from "@/Components/Common/CommonForm";

const Discount = ({ props }) => {
  const {
    plan,
    formRef,
    formValues,
    upgradePlan,
    cancelPlan,
    submitPromocode,
    trialDays,
    profileData,
    urlPromoCode,
    minuteTrialText,
  } = props;
  const [promoFormValue, setPromoFormValue] = useState(formValues);
  const { t } = useTranslation();
  promocodeFormFields[0].placeholder = t(`pricing.Enter Promocode`);

  let trialText =
    profileData.trial_days === undefined || trialDays === 7
      ? t(`pricing.7 days free trial, No Credit card required`)
      : trialDays > 0 && trialDays < 7
      ? `${t("pricing.Your remaining trial days are")} ${trialDays}. ${t("pricing.You have used")} ${
          7 - trialDays
        } ${t("pricing.days.")}`
      : t(`pricing.No trial days remaining. You have already used the 7-day free trial.`);

  return (
    <React.Fragment>
      {plan.discountLabel && (
        <InlineStack blockAlign="center" align="space-between">
          <Text variant="headingSm" as="h3" tone="subdued">
            <span className="line-through">${plan.monthlyPrice ? plan.monthlyPrice : plan.price}</span>
          </Text>
          <InlineStack gap="100" blockAlign="center" wrap={false}>
            <Icon source={DiscountsMinor} tone="success" />
            {plan.discountValue ? (
              <Text as="p" tone="success">
                {plan.discountLabel} {t(`pricing.Discount applied`)}
              </Text>
            ) : (
              <Text as="p" tone="success">
                {Math.round(100 - (plan.finalPrice * 100) / (plan.monthlyPrice || plan.price)) + "%"}{" "}
                {t(`pricing.Discount applied`)}
              </Text>
            )}
          </InlineStack>
        </InlineStack>
      )}
      {!plan.isPromoInputHidden && !plan.selected && (
        <InlineStack gap={100} wrap={false}>
          <CommonForm
            onSubmit={submitPromocode}
            initialValues={promoFormValue}
            formFields={promocodeFormFields}
            formRef={formRef}
            isSave={false}
            onFormChange={(e) => setPromoFormValue(e)}
          />
          <Button small onClick={() => submitPromocode(plan)}>
            {t(`pricing.Apply`)}
          </Button>
        </InlineStack>
      )}
      {(plan.touched || urlPromoCode) && plan.discountValue && (
        <Text tone="success">
          {t(`pricing.Your code(s)`)} <b>{plan["discountValue"]}</b>{" "}
          {t(`pricing.and discount have been applied successfully`)}
        </Text>
      )}
      {plan.touched && !plan.discountValue && !promoFormValue?.promocode && (
        <InlineStack gap="200" blockAlign="center" wrap={false}>
          <Icon source={DiamondAlertMajor} tone="critical" />
          <Text tone="critical"> {t(`pricing.Your entered promocode is not correct, Enter a valid code`)}</Text>
        </InlineStack>
      )}

      {plan.selected && plan.type === "recurring" && (
        <InlineStack gap="100" blockAlign="center" wrap={false}>
          <Icon source={ClockMajor} tone="base" />
          <Text as="p">
            {minuteTrialText} {t(`pricing.trial left`)}
          </Text>
        </InlineStack>
      )}
      {plan.type === "recurring" &&
        (plan?.disableActiveButton ? (
          <Tooltip content={t("pricing.disableButtonTooltip")} width="wide">
            <Button variant="primary" fullWidth disabled={true}>
              {t("pricing.Upgrade your plan now")}
            </Button>
          </Tooltip>
        ) : (
          <Button
            variant="primary"
            fullWidth
            onClick={() => (plan.selected ? cancelPlan(plan) : upgradePlan(plan))}
          >
            {plan.trial && !plan.selected
              ? trialDays === 0 && profileData.trial_days !== undefined
                ? t("pricing.Upgrade your plan now")
                : `${t("pricing.Start")} ${profileData.trial_days === undefined ? plan.trial.days : trialDays} ${t(
                    "pricing.Day Free Trial"
                  )}`
              : t(`pricing.Cancel Plan`)}
          </Button>
        ))}
      {plan.type === "onetime" && !plan.selected && (
        <Button variant="primary" fullWidth onClick={() => upgradePlan(plan)}>
          {t(`pricing.Upgrade`)}
        </Button>
      )}
      {plan.trial && plan.intervalLable !== "Unlimited" && !plan.selected && (
        <Text variant="headingSm" as="h6" alignment="center">
          {trialText}
        </Text>
      )}
    </React.Fragment>
  );
};

export default function SinglePlan(props) {
  const { t } = useTranslation();
  const { plan } = props;
  return (
    <div
      className={`pricing-card`}
      style={
        plan?.selected
          ? {
              border: "2px solid #008060",
              borderRadius: "15px",
              boxShadow: "0 0 .3125rem #aee9d1,0 .0625rem .125rem #aee9d1",
            }
          : {}
      }
    >
      <Card padding="400" background={"bg-surface"}>
        <BlockStack gap="400">
          <InlineStack blockAlign="center" align="space-between">
            <Text variant="headingLg" as="h2">
              {plan.name}
            </Text>
            {plan.selected && (
              <Badge tone="success">
                <Text variant="bodyMd">{t(`pricing.Current`)}</Text>
              </Badge>
            )}
          </InlineStack>
          <InlineStack blockAlign="center" gap="100">
            <Text variant="heading3xl" as="h3">
              ${plan.finalPrice}
            </Text>
            {plan.intervalLable && (
              <Text variant="headingSm" as="h6" tone="subdued">
                /{t(`pricing.${plan.intervalLable}`)}
              </Text>
            )}
          </InlineStack>
          {plan.price !== 0 ? <Discount props={props} /> : <Box minHeight="100px"></Box>}
          <Divider></Divider>
          <BlockStack gap="400">
            {plan.features.map((feature, index) => (
              <InlineStack gap="200" key={index} blockAlign="center" wrap={false}>
                <Icon size="large" source={CircleTickMajor} tone="success" className="custom-icon" />
                {feature.dynamic ? (
                  <Text wrap as="p">
                    {eval(feature.dynamicText)} {t(`pricing.features.${feature.text}`)}
                  </Text>
                ) : (
                  <Text wrap as="p">
                    {t(`pricing.features.${feature.text}`)}
                  </Text>
                )}
              </InlineStack>
            ))}
          </BlockStack>
        </BlockStack>
      </Card>
    </div>
  );
}
