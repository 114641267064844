import { differenceInCalendarMonths, differenceInDays, differenceInYears, formatDistanceToNow } from "date-fns";
import { t } from "i18next";
import { ABLogo, CCLogo, LinkifyLogo, SeoLogo } from "@/Assets/Index";

export const paidFeatures = [
  {
    text: "7 Days Risk-Free Trial Period",
    icon: "",
  },
  {
    text: "Language & country Dropdown",
    icon: "",
  },
  // {
  //   text: "Third Party Script Integration",
  //   icon: "",
  // },
  {
    text: "IP Based Country Detection",
    icon: "",
  },
  {
    text: "Custom Position / Customize The Currency Dropdown Switcher position on your website at anywhere",
    icon: "",
  },
  {
    text: "Beautiful Country Flags",
    icon: "",
  },
  {
    text: "Hide Country/Currencies as per your Choice",
    icon: "",
  },
  {
    text: "Price Rounding and Manual Rate Availability For Effective Conversation",
    icon: "",
  },
  {
    text: "Urgent Priority Support within 24 hours response for setup assistance.",
    icon: "",
  },
];

export const freeFeatures = [
  {
    text: "175+ Currencies on Currency Dropdown",
    icon: "",
  },
  {
    text: "Search Option of Currency and Country",
    icon: "",
  },
  {
    text: "Dropdown styles customization",
    icon: "",
  },
  {
    text: "Dropdown content customization",
    icon: "",
  },
  {
    text: "Country-based rules",
    icon: "",
  },
  {
    text: "General currency rules",
    icon: "",
  },
  {
    text: "Custom Cart notification",
    icon: "",
  },
  {
    text: "Chat support",
    icon: "",
  },
];

export const featureList = {
  Free: freeFeatures,
  Premium: paidFeatures,
  Lifetime: paidFeatures,
  Premium100: paidFeatures,
  Annual: paidFeatures,
  "Default-feature": paidFeatures,
  Pro100: paidFeatures,
};
export const interval = {
  Premium: "EVERY_30_DAYS",
  Lifetime: "",
  Premium100: "EVERY_30_DAYS",
  Default: "",
};

const monthlyCodes = [
  {
    name: "Promotional",
    code: "PE4ACENT",
    type: "percentage", // can be (amount|percentage)
    value: 5,
  },
  {
    name: "Promotional",
    code: "OS1HOR",
    type: "percentage",
    value: 10,
  },
  {
    name: "Promotional",
    code: "SO2ULA",
    type: "percentage",
    value: 15,
  },
  {
    name: "Promotional",
    code: "TG3KSD",
    type: "percentage",
    value: 20,
  },
  {
    name: "Promotional",
    code: "RU4LIX",
    type: "percentage",
    value: 25,
  },
  {
    name: "Promotional",
    code: "BW5JAK",
    type: "percentage",
    value: 30,
  },
  {
    name: "Promotional",
    code: "SI6LENPM",
    type: "percentage",
    value: 35,
  },
  {
    name: "Promotional",
    code: "IN0NERDP",
    type: "percentage",
    value: 40,
  },
  {
    name: "Promotional",
    code: "AW3AKEPS",
    type: "percentage",
    value: 45,
  },
  {
    name: "Promotional",
    code: "EN7GHLIG",
    type: "percentage",
    value: 50,
  },
];

const yearlyCodes = [
  {
    name: "Promotional",
    code: "OS1HOR",
    type: "percentage",
    value: 30,
  },
  {
    name: "Promotional",
    code: "SO2ULA",
    type: "percentage",
    value: 35,
  },
  {
    name: "Promotional",
    code: "TG3KSD",
    type: "percentage",
    value: 40,
  },
  {
    name: "Promotional",
    code: "RU4LIX",
    type: "percentage",
    value: 45,
  },
  {
    name: "Promotional",
    code: "BW5JAK",
    type: "percentage",
    value: 50,
  },
];

export const getPlansData = () => [
  {
    type: "free",
    id: "Free",
    name: "Free",
    price: 0,
    isHidden: false,
    features: [...featureList["Free"]],
  },
  {
    type: "recurring",
    is_recurring: true,
    billingInterval: "Month", // it could be (Year | Month | Unlimited) for differentiate plans in database
    intervalLable: "Month", // it for showing label in plan
    interval: "EVERY_30_DAYS",
    id: "Premium",
    name: "Premium",
    price: 9.99,
    isHidden: "hasBillingButton ? !isFirstButtonActive : false", //value true or false
    isPromoInputHidden: false,
    features: [...featureList["Premium"]],
    discounts: [...monthlyCodes],
    trial: {
      days: 7,
    },
    badge: {
      text: "POPULAR",
    },
  },
  {
    type: "recurring", //it could be (recurring|onetime)
    is_recurring: true,
    billingInterval: "Year", // it could be (Year | Month | Unlimited) for differentiate plans in database
    intervalLable: "Month", // it for showing label in plan
    interval: "ANNUAL",
    id: "Annual",
    name: "Annual",
    isHidden: "hasBillingButton ? isFirstButtonActive : false", //value true or false
    monthlyPrice: 9.99,
    price: 119.88,
    isPromoInputHidden: false,
    features: [...featureList["Annual"]],
    trial: {
      days: 7,
    },
    discounts: [...yearlyCodes],
    initialDiscountPrice: 6.99,
    initialDiscountObject: { type: "percentage", value: 30 },
    discountPercent: 30,
  },
];

export const promocodeFormFields = [
  {
    id: "promocode",
    name: "promocode",
    labelPosition: "right",
    type: "text",
    errormsg: "Promocode is required",
    placeholder: "Enter Promocode",
  },
];

export const initialValues = {
  promocode: "",
};

export const formFieldsCancelReason = [
  {
    id: "cancelReason",
    name: "cancelReason",
    label: "Please Choose a Cancellation Reason",
    nested: "object",
    groupSize: 2,
    section: false,
    subfields: [
      {
        radioId: "noLongerNeeded",
        id: "noLongerNeeded",
        name: "reason",
        label: "No Longer Needed?",
        type: "radio",
      },
      {
        radioId: "dontWork",
        id: "dontWork",
        name: "reason",
        label: "Didn't Meet Expectations",
        type: "radio",
      },
      {
        radioId: "costConcerns",
        id: "costConcerns",
        name: "reason",
        label: "Cost Concerns",
        type: "radio",
      },
      {
        radioId: "technicalIssues",
        id: "technicalIssues",
        name: "reason",
        label: "Technical Issues",
        type: "radio",
      },
      {
        radioId: "foundAlternative",
        id: "foundAlternative",
        name: "reason",
        label: "Found an Alternative",
        type: "radio",
      },
      {
        radioId: "complexity",
        id: "complexity",
        name: "reason",
        label: "Complexity",
        type: "radio",
      },
      {
        radioId: "lackFeatures",
        id: "lackFeatures",
        name: "reason",
        label: "Lack of Features",
        type: "radio",
      },
      {
        radioId: "poorCustomerSupport",
        id: "poorCustomerSupport",
        name: "reason",
        label: "Poor Customer Support",
        type: "radio",
      },
      {
        radioId: "businessClosure",
        id: "businessClosure",
        name: "reason",
        label: "Business Closure or Change",
        type: "radio",
      },
      {
        radioId: "temporaryPause",
        id: "temporaryPause",
        name: "reason",
        label: "Temporary Pause",
        type: "radio",
      },
      {
        radioId: "performanceIssues",
        id: "performanceIssues",
        name: "reason",
        label: "Performance Issues",
        type: "radio",
      },
      {
        radioId: "other",
        id: "other",
        name: "reason",
        label: "Other",
        type: "radio",
      },
      {
        id: "value",
        name: "value",
        label: "Mention Your Specific Reason Here",
        labelPosition: "left",
        type: "text",
      },
    ],
  },
];

export const cancelReasonInitialValues = {
  cancelReason: {
    reason: "",
    value: "",
  },
};

const dateWisePrice = (date, billingInterval) => {
  const day = differenceInDays(new Date(), new Date(date));
  if (day <= 60) {
    return billingInterval === "Year" ? 1.99 : 2.99;
  } else if (day > 60 && day <= 180) {
    return billingInterval === "Year" ? 2.49 : 3.99;
  } else if (day > 180 && day <= 365) {
    return billingInterval === "Year" ? 2.99 : 4.49;
  } else {
    return billingInterval === "Year" ? 3.49 : 4.99;
  }
};

export const dateWisePriceObj = (date, plan) => {
  const { billingInterval } = plan;
  const monthlyPrice = 4.99;
  const finalPrice = dateWisePrice(date, billingInterval);
  const persent = Math.round(100 - (finalPrice * 100) / 4.99);
  let finalObj = {};
  if (finalPrice !== 4.99) {
    finalObj = {
      initialDiscountPrice: finalPrice,
      initialDiscountObject: { type: "percentage", value: persent },
      discountPercent: persent,
    };
  }
  const price = billingInterval === "Year" ? monthlyPrice * 12 : monthlyPrice;
  if (plan?.id !== "Free") {
    return billingInterval === "Year" ? { price, monthlyPrice, ...finalObj } : { price, ...finalObj };
  } else {
    return {};
  }
};
export const dateWisePersent = (date, isFirstButtonActive) => {
  const billingInterval = isFirstButtonActive ? "Month" : "Year";
  const finalPrice = dateWisePrice(date, billingInterval);
  const persent = Math.round(100 - (finalPrice * 100) / 4.99);
  return persent > 0 ? persent : false;
};

const makeBanner = (distanceText, persent, bannerKey) => {
  if (persent > 0) {
    const bannerMsg = `${t(`pricing.dateWiseBanner.${bannerKey}MsgStart`)} ${distanceText}. ${t(
      `pricing.dateWiseBanner.${bannerKey}MsgMiddle`
    )} ${persent}% ${t(`pricing.dateWiseBanner.${bannerKey}MsgEnd`)}`;
    const bannerTitle = `${t(`pricing.dateWiseBanner.${bannerKey}TitleStart`)} ${persent}% ${t(
      `pricing.dateWiseBanner.${bannerKey}TitleEnd`
    )} ${distanceText}.`;
    return { bannerMsg, bannerTitle };
  } else {
    const bannerMsg = `${t("pricing.dateWiseBanner.generalMsg")}`;
    const bannerTitle = `${t("pricing.dateWiseBanner.veryImportantTitleStart")} ${distanceText} ${t(
      "pricing.dateWiseBanner.veryImportantTitleEnd"
    )}.`;
    return { bannerMsg, bannerTitle };
  }
};

export const dateWisePriceBanner = (date, isFirstButtonActive) => {
  const day = differenceInDays(new Date(), new Date(date));
  const distanceText = formatDistanceToNow(new Date(date), { addSuffix: true });
  const billingInterval = isFirstButtonActive ? "Month" : "Year";
  const finalPrice = dateWisePrice(date, billingInterval);
  const persent = Math.round(100 - (finalPrice * 100) / 4.99);

  if (day <= 60) {
    return makeBanner(distanceText, persent, "low");
  } else if (day > 60 && day <= 180) {
    return makeBanner(distanceText, persent, "medium");
  } else if (day > 180 && day <= 365) {
    return makeBanner(distanceText, persent, "important");
  } else {
    let bannerMsg = ``;
    const differenceInMonth = differenceInCalendarMonths(new Date(), new Date(date));
    const differenceInYear = differenceInYears(new Date(), new Date(date));
    const finalMonth = differenceInMonth - differenceInYear * 12;
    let monthYearText = ``;
    if (finalMonth > 0) {
      monthYearText = `${differenceInYear} ${t("pricing.dateWiseBanner.year and")} ${finalMonth} ${t(
        "pricing.dateWiseBanner.month"
      )}`;
    } else {
      monthYearText = `${differenceInYear} ${t("pricing.dateWiseBanner.year")}`;
    }
    if (persent > 0) {
      bannerMsg = `${t("pricing.dateWiseBanner.veryImportantMsgStart")}  ${monthYearText} ${t(
        "pricing.dateWiseBanner.ago"
      )}. ${t("pricing.dateWiseBanner.veryImportantMsgMiddle")} ${persent}% ${t(
        "pricing.dateWiseBanner.veryImportantMsgEnd"
      )}`;
    } else {
      bannerMsg = `${t("pricing.dateWiseBanner.generalMsg")}`;
    }
    const bannerTitle = `${t("pricing.dateWiseBanner.veryImportantTitleStart")} ${monthYearText} ${t(
      "pricing.dateWiseBanner.veryImportantTitleEnd"
    )}.`;
    return { bannerMsg, bannerTitle };
  }
};

export const AppList = [
  {
    title: "Webrex SEO: AI, Speed & Schema",
    tagLine: "Optimize your Store by align correct SEO to improve organic ranking, boost visibility",
    link: "https://apps.shopify.com/breadcrumbs-schemas?source=Currency-converter-app",
    image: SeoLogo,
    starRate: 4.9,
    reviweCount: 437,
    popular: true,
    // hide: true,
  },
  {
    title: "WebRex Multi Announcement Bar",
    tagLine: "Boost sales with customizable bars for announcements, free shipping, and countdown timers",
    link: "https://apps.shopify.com/announcement-bar-with-slider?source=Currency-converter-app",
    image: ABLogo,
    starRate: 4.9,
    reviweCount: 436,
    // hide: true,
    // popular: true,
  },
  {
    title: "Linkify ‑ Backlink SEO Booster",
    tagLine: "Supercharge SEO with top-tier backlinks from high DA sites. Boost rankings, traffic, and authority",
    link: "https://apps.shopify.com/linkify-app?source=Currency-converter-app",
    image: LinkifyLogo,
    starRate: 5.0,
    reviweCount: 8,
    hide: true,
    // popular: true,
  },
  {
    title: "Webrex ‑ Currency Converter",
    tagLine: "Solution for your International Selling that will help to show converted prices, local currency",
    link: "https://apps.shopify.com/currency-converter-11?source=Currency-converter-app",
    image: CCLogo,
    starRate: 4.9,
    reviweCount: 306,
    hide: true,
    // popular: true,
  },
];
