import React, { useRef, useState } from "react";
import { Button, Modal } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import CommonForm from "@/Components/Common/CommonForm";

const formField = [
  { label: "Add key", id: "otherKey", name: "otherKey", validated: true, type: "text" },
  { label: "Add value of key", id: "otherValue", name: "otherValue", validated: true, type: "text" },
];

export default function AddOtherObj({ row, childRef }) {
  const [openModal, setOpenModal] = useState(false);

  const fetch = useAuthenticatedFetch();
  const formRef = useRef();

  const addExtraObject = async (val) => {
    setOpenModal(false);
    await fetch.put(`admin/updateUser`, {
      _id: row?._id,
      [`extraObject.${val?.otherKey}`]: val?.otherValue,
    });
    childRef?.current?.fetchData();
  };

  const submitForm = () => {
    if (formRef.current) formRef.current.handleSubmit();
  };

  return (
    <>
      <Button onClick={() => setOpenModal(true)}>Add Other Object In User Table</Button>
      <Modal
        open={openModal}
        title="Are you sure you want to sync Metafield?"
        onClose={() => setOpenModal(false)}
        primaryAction={{
          content: "Save",
          onAction: submitForm,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: () => setOpenModal(false),
          },
        ]}
        size="small"
      >
        <Modal.Section>
          <CommonForm
            onSubmit={addExtraObject}
            formRef={formRef}
            initialValues={{ otherKey: "", otherValue: "" }}
            formFields={formField}
            isSave={false}
          />
        </Modal.Section>
      </Modal>
    </>
  );
}
