import React, { useEffect, useState } from "react";
import { Banner } from "@shopify/polaris";
import {
  getLocalStorageItem,
  getSessionStorageItem,
  setLocalStorageItem,
  setSessionStorageItem,
} from "@/Utils/Index";

export default function DissmissableBanner(props) {
  const { bannerName, bannerText } = props;
  const [showBanner, setShowBanner] = useState(false);

  const DissmissableBanner = () => {
    // setSessionStorageItem(bannerName, true);
    setLocalStorageItem(bannerName, true);
    setShowBanner(false);
  };

  useEffect(() => {
    // const getItem = getSessionStorageItem(bannerName);
    const getItem = getLocalStorageItem(bannerName);
    if (!getItem) setShowBanner(true);
    else setShowBanner(false);
  }, [bannerName]);

  return (
    <>
      {showBanner && (
        <Banner
          {...props}
          onDismiss={() => {
            DissmissableBanner();
          }}
        >
          {bannerText}
        </Banner>
      )}
    </>
  );
}
