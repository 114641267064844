import { t } from "i18next";
import CurrenciesObj from "@/Utils/widget/Utils/currencies.json";
import AppRuleData from "../Assets/AppRule.json";
import CurrenciesData from "../Assets/currencies.json";

export const getMoneyFormatPreview = ({
  currencyRule = {},
  generalRule = {},
  toCurrency = "USD",
  profileData,
  fromCurrency = "USD",
  liveCurrency,
  money = 1,
  manualRate = {},
  countryRule = {},
  currencySymbol,
}) => {
  currencySymbol = profileData?.recurringPlanId !== "Free" ? currencySymbol : false;
  const currencyRulePlanWise = profileData?.recurringPlanId !== "Free" ? currencyRule : {};
  const currentRule = { ...generalRule, ...currencyRulePlanWise, ...countryRule };
  const { moneyFormatObj, priceRoundingObj, priceRounding, moneyFormat } = currentRule || "";
  const liveRate = liveCurrency ? liveCurrency[fromCurrency] / liveCurrency[toCurrency] : 100;
  const chooseRate =
    ((manualRate?.manualExchange === "true" || manualRate?.manualExchange == true) &&
    profileData?.recurringPlanId !== "Free"
      ? manualRate?.manualExchangeRate
      : liveRate) * money;
  const elementPrice = priceRounding ? getRoundAmountNew(priceRoundingObj, chooseRate, toCurrency) : chooseRate;
  if (moneyFormat && moneyFormatObj?.moneyFormat === "strict") {
    const { leftSpace, rightSpace, leftAttach, rightAttach, moneyFormatOption } = moneyFormatObj;
    const lSpace = leftSpace == 1 ? " " : "";
    const rSpace = rightSpace == 1 ? " " : "";
    const lAttachValue = getAttachValue(leftAttach, toCurrency, currencySymbol);
    const rAttachValue = getAttachValue(rightAttach, toCurrency, currencySymbol);
    const moneyFormatValue = getMoneyFormatWise(elementPrice * 100, moneyFormatOption) || " ";
    const preview = `${lAttachValue}${lSpace}${moneyFormatValue}${rSpace}${rAttachValue}`;
    return preview;
  } else {
    const moneyFormatOption = AppRuleData?.[toCurrency]?.moneyFormat || "Symbol {{ amount }}";
    const leftAttach = getAttachValue(moneyFormatOption?.split("{{")?.[0], toCurrency, currencySymbol);
    const rightAttach = getAttachValue(moneyFormatOption?.split("}}")?.[1], toCurrency, currencySymbol);
    const lSpace = moneyFormatOption?.includes(" {{") ? " " : "";
    const rSpace = moneyFormatOption?.includes("}} ") ? " " : "";
    const moneyFormatValue = getMoneyFormatWise(elementPrice * 100, moneyFormatOption) || " ";
    const preview = `${leftAttach}${lSpace}${moneyFormatValue}${rSpace}${rightAttach}`;
    return preview;
  }
};

const getAttachValue = (attach = "", currency, currencySymbol) => {
  if (attach.includes("none")) return "";
  else if (attach.includes("Currency")) return currency;
  else if (attach.includes("Symbol"))
    return currencySymbol || currencySymbol === ""
      ? currencySymbol
      : CurrenciesData?.[currency]?.symbol_native || "";
  else return attach || "";
};

function getRoundAmountNew(to, amount, currency) {
  try {
    to = { ...to, ...AppRuleData[currency] };
    let roundingObj = to;
    let round, base;
    if (!roundingObj.roundingRule) {
      if (to.decimal == 0) {
        round = parseFloat(roundingObj.roundingRuleBase100);
        base = 100;
      } else if (to.decimal > 0) {
        round = parseFloat(roundingObj.roundingRuleBase1);
        base = 1;
      }
    } else {
      round = parseFloat(roundingObj.roundingRule);
      base = roundingObj.roundingRule <= 1 ? 1 : 100;
    }
    let reminder = (amount % base).toFixed(2);
    let value = parseInt(amount / base) * base;
    if (reminder > round) {
      return value + base + round;
    } else {
      if (round == base && reminder == 0) {
        return value;
      } else {
        return value + round;
      }
    }
  } catch (error) {
    throw error;
  }
}

const getMoneyFormatWise = (cents, format) => {
  var value;
  var placeholderRegex = /\{\{\s*(\w+)\s*\}\}/;
  var formatString = format;
  switch (formatString.match(placeholderRegex)[1]) {
    case "amount":
      value = formatWithDelimiters(cents, 2);
      break;
    case "amount_no_decimals":
      value = formatWithDelimiters(cents, 0);
      break;
    case "amount_with_comma_separator":
      value = formatWithDelimiters(cents, 2, ".", ",");
      break;
    case "amount_no_decimals_with_comma_separator":
      value = formatWithDelimiters(cents, 0, ",");
      break;
    case "amount_with_apostrophe_separator":
      value = formatWithDelimiters(cents, 2, "'", ".");
      break;
    case "amount_no_decimals_no_comma_separator":
      value = formatWithDelimiters(cents, 0, "");
      break;
    case "amount_no_decimals_with_space_separator":
      value = formatWithDelimiters(cents, 0, " ");
      break;
    case "amount_with_space_separator":
      value = formatWithDelimiters(cents, 2, " ", ",");
      break;
    case "amount_with_period_and_space_separator":
      value = formatWithDelimiters(cents, 2, " ", ".");
      break;
  }
  return value; // Return the formatted value
};

const defaultOption = (opt, def) => {
  return typeof opt == "undefined" ? def : opt;
};

const formatWithDelimiters = (number, precision, thousands, decimal) => {
  precision = defaultOption(precision, 2);
  thousands = defaultOption(thousands, ",");
  decimal = defaultOption(decimal, ".");

  if (isNaN(number) || number == null) {
    return 0;
  }

  number = (number / 100.0).toFixed(precision);

  var parts = number.split("."),
    dollars = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1" + thousands),
    cents = parts[1] ? decimal + parts[1] : "";

  return dollars + cents;
};

export const getshopifyMoneyFormatOption = () => [
  {
    label: `{{ ${t("general.amount")} }}`,
    value: "{{ amount }}",
  },
  {
    label: `{{ ${t("general.amount_no_decimals")} }}`,
    value: "{{ amount_no_decimals }}",
  },
  {
    label: `{{ ${t("general.amount_with_comma_separator")} }}`,
    value: "{{ amount_with_comma_separator }}",
  },
  {
    label: `{{ ${t("general.amount_no_decimals_with_comma_separator")} }}`,
    value: "{{ amount_no_decimals_with_comma_separator }}",
  },
  {
    label: `{{ ${t("general.amount_with_apostrophe_separator")} }}`,
    value: "{{ amount_with_apostrophe_separator }}",
  },
  {
    label: `{{ ${t("general.amount_no_decimals_no_comma_separator")} }}`,
    value: "{{ amount_no_decimals_no_comma_separator }}",
  },
  {
    label: `{{ ${t("general.amount_no_decimals_with_space_separator")} }}`,
    value: "{{ amount_no_decimals_with_space_separator }}",
  },
  {
    label: `{{ ${t("general.amount_with_space_separator")} }}`,
    value: "{{ amount_with_space_separator }}",
  },
  {
    label: `{{ ${t("general.amount_with_period_and_space_separator")} }}`,
    value: "{{ amount_with_period_and_space_separator }}",
  },
];

export const spaceOptions = [
  {
    label: "0",
    value: "0",
  },
  {
    label: "1",
    value: "1",
  },
];

export const getattachOptions = () => [
  {
    label: t("general.none"),
    value: "none",
  },
  {
    label: t("general.Symbol"),
    value: "Symbol",
  },
  {
    label: t("general.Currency"),
    value: "Currency",
  },
];

export let roundingRuleOption = [];
let baseHundred = [
  "100",
  "99",
  "95",
  "90",
  "75",
  "50",
  "25",
  "10",
  "1",
  "0.99",
  "0.95",
  " 0.9",
  "0.75",
  "0.5",
  "0.25",
  "0.1",
];
for (let i of baseHundred) {
  roundingRuleOption.push({
    label: i,
    value: i,
  });
}

export const getMoneyFormatRule = (value) => {
  const { leftAttach, leftSpace, moneyFormatOption, rightAttach, rightSpace } = value.moneyFormatObj;
  const lSpace = leftSpace == 1 ? " " : "";
  const rSpace = rightSpace == 1 ? " " : "";
  const lAttachValue = leftAttach == "none" ? "" : leftAttach;
  const rAttachValue = rightAttach == "none" ? "" : rightAttach;
  const moneyFormatValue = moneyFormatOption || " ";
  const preview = `${lAttachValue}${lSpace}${moneyFormatValue}${rSpace}${rAttachValue}`;
  return preview;
};

const currencyFormatting = (str) => {
  let firstSplit = str.split("{{")[1];
  let secondSplit = firstSplit.split("}}")[0];
  return !secondSplit || secondSplit == "" ? "{{amount}}" : secondSplit;
};

const isMoneyClassAvailable = (str) => {
  if (str.includes("class")) {
    let firstSplit = str.split("class=")[1];
    if (firstSplit && firstSplit.length > 0) {
      let classQuate = firstSplit.charAt(0);
      let secondSplit = firstSplit.split(classQuate)[1];
      let thirdSplit = secondSplit.split(" ");
      if (thirdSplit.find((e) => e == "money")) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const checkMoneyFormat = (profileData, integrationRes) => {
  let defaultCurrency = CurrenciesData[profileData?.currency];
  if (defaultCurrency) {
    let finalAmountStr = currencyFormatting(integrationRes.data.body.data.shop.currencyFormats.moneyFormat);
    let text1 = `<span class='money'>${defaultCurrency["symbol_native"]}{{${finalAmountStr}}}${defaultCurrency["currency"]}</span>`;
    let text2 = `<span class='money'>${defaultCurrency["symbol_native"]}{{${finalAmountStr}}}</span>`;
    if (
      isMoneyClassAvailable(integrationRes.data.body.data.shop.currencyFormats.moneyFormat) &&
      isMoneyClassAvailable(integrationRes.data.body.data.shop.currencyFormats.moneyWithCurrencyFormat)
    ) {
      return { integrated: true, text1, text2 };
    } else return { integrated: false, text1, text2 };
  } else return { showHint: true };
};

export const newMoneyFormatPreview = ({ currentVal, profileData, liveCurrency, money = 1 }) => {
  const fromCurrency = profileData?.currency || "USD";
  const { currency, configuration, symbol } = currentVal;
  const { moneyFormat, priceRounding, manualExchange } = configuration || {};
  let moneyFormatObj;
  let priceRoundingObj;
  let manualExchangeRate;
  if (profileData?.recurringPlanId !== "Free") {
    if (moneyFormat) moneyFormatObj = configuration?.moneyFormatObj;
    if (priceRounding) priceRoundingObj = configuration?.priceRoundingObj;
    if (manualExchange == "true") manualExchangeRate = configuration?.manualExchangeRate;
  }
  const liveRate = liveCurrency ? liveCurrency[fromCurrency] / liveCurrency[currency] : 100;
  const chooseRate = (manualExchangeRate ? manualExchangeRate : liveRate) * money;
  const elementPrice = priceRoundingObj ? getRoundAmountNew(priceRoundingObj, chooseRate, currency) : chooseRate;
  if (moneyFormatObj) {
    const { leftSpace, rightSpace, leftAttach, rightAttach, moneyFormatOption } = moneyFormatObj;
    const lSpace = leftSpace == 1 ? " " : "";
    const rSpace = rightSpace == 1 ? " " : "";
    const lAttachValue = getAttachValue(leftAttach, currency, symbol);
    const rAttachValue = getAttachValue(rightAttach, currency, symbol);
    const moneyFormatValue = getMoneyFormatWise(elementPrice * 100, moneyFormatOption) || " ";
    const preview = `${lAttachValue}${lSpace}${moneyFormatValue}${rSpace}${rAttachValue}`;
    return preview;
  } else {
    const moneyFormatOption = CurrenciesObj?.[currency]?.moneyFormat || "Symbol {{ amount }}";
    let leftAttach = moneyFormatOption?.split("{{")?.[0];
    let rightAttach = moneyFormatOption?.split("}}")?.[1];
    const lSpace = moneyFormatOption?.includes(" {{") ? " " : "";
    const rSpace = moneyFormatOption?.includes("}} ") ? " " : "";
    if (leftAttach && leftAttach !== `${currency}${lSpace}`) leftAttach = symbol;
    if (rightAttach && rightAttach !== `${rSpace}${currency}`) rightAttach = symbol;
    const moneyFormatValue = getMoneyFormatWise(elementPrice * 100, moneyFormatOption) || " ";
    const preview = `${leftAttach}${lSpace}${moneyFormatValue}${rSpace}${rightAttach}`;
    return preview;
  }
};
