import { t } from "i18next";
import { isAdmin } from "@/Utils/Index";

let getNavigationLinks = () => [
  {
    // label: t("mocks.navigation.Country Rules"),
    label: t("mocks.navigation.Add Country"),
    destination: "/country",
  },
  {
    // label: t("mocks.navigation.Dropdown"),
    label: t("mocks.navigation.Add Selector"),
    destination: "/dropdown-config",
  },
  // {
  //   label: t("mocks.navigation.Third Party App"),
  //   destination: "/script-integration",
  // },
  {
    label: t("mocks.navigation.Checkout Notification"),
    destination: "/cart-notification",
  },
  {
    label: t("mocks.navigation.Custom CSS"),
    destination: "/customCss",
  },

  {
    label: t("mocks.navigation.Price"),
    destination: "/pricing",
  },
  {
    label: t("mocks.navigation.Settings"),
    destination: "/settings",
  },
  // {
  //   label: "FAQs",
  //   destination: "/videoFaqs",
  // },
  // {
  //   label: t("mocks.navigation.Feedback"),
  //   destination: "/feedback",
  // },
];

let admin = isAdmin();
if (admin) {
  getNavigationLinks = () => [
    {
      label: t("mocks.navigation.Add Country"),
      destination: "/country",
    },
    {
      label: t("mocks.navigation.Add Selector"),
      destination: "/dropdown-config",
    },
    // {
    //   label: t("mocks.navigation.Third Party App"),
    //   destination: "/script-integration",
    // },
    {
      label: t("mocks.navigation.Checkout Notification"),
      destination: "/cart-notification",
    },
    {
      label: t("mocks.navigation.Custom CSS"),
      destination: "/customCss",
    },

    {
      label: t("mocks.navigation.Price"),
      destination: "/pricing",
    },
    {
      label: t("mocks.navigation.Settings"),
      destination: "/settings",
    },
    {
      label: "Selectors",
      destination: "/selectors",
    },
    // {
    //   label: "FAQs",
    //   destination: "/videoFaqs",
    // },
    {
      label: "Country-old",
      destination: "/country-old",
    },
    {
      label: "Currency-old",
      destination: "/currency-old",
    },
    {
      label: "General-old",
      destination: "/general-old",
    },
  ];
}

export { getNavigationLinks };

// {
//   label: "Form Preview",
//   destination: "/form-preview",
// },
// {
//   label: "Help & Support",
//   destination: "/faqs",
// },
// {
//   label: "Selectors",
//   destination: "/selectors",
// },
// {
//   label: "Other",
//   destination: "/other",
// },
